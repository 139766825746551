import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import LocationSearch from "../LocationSearch";
import Dropdown from "../Dropdown";
import Reservations from "../Reservations";
import logo from "./logo.png";
import logoSmall from "./logo-small.png";

class Nav extends PureComponent {
	componentDidMount() {
		const { fetchProfile, user, token } = this.props;
		if (token) {
			// this.checkToken();
			fetchProfile(token);
		}
	}

	componentDidUpdate() {
		const { token } = this.props;
		if (token) {
			// this.checkToken();
		}
	}

	checkToken() {
		// Log out if token is due to expire. TODO: refresh token
		const { token, tokenExpiration, signOut } = this.props;
		const expiresIn = new Date(tokenExpiration) - new Date();
		const hourMilliseconds = 1000 * 60 * 60;
		const invalidExpires = token && !expiresIn;
		const isExpiringSoon = token && expiresIn < hourMilliseconds;
		if (invalidExpires || isExpiringSoon) {
			signOut();
		}
	}

	render() {
		const { location, closeDropdown } = this.props;
		const { pathname } = location;
		const notHome = pathname !== "/";
		const searchPage = pathname.indexOf("/s/") === 0;
		const hotelPage = pathname.indexOf("/hotel") === 0;
		const bookPage = pathname.indexOf("/book") === 0;
		const reservationsPage = pathname.indexOf("/reservations") === 0;
		const termsPage = pathname === "/terms";
		const privacyPage = pathname === "/privacy";
		const accountPage = pathname === "/account";
		if (termsPage || privacyPage) return null;
		const searchHotelClass =
			searchPage || reservationsPage || accountPage
				? "bg-white bb b--light-gray"
				: "";
		const homeLogoClass = pathname === "/" ? "white" : "black";
		return (
			<header>
				<div
					className={`${
						notHome ? "fixed-ns bg-white" : "absolute"
					} w-100 z-5 ${searchHotelClass} ${homeLogoClass} ${
						bookPage || hotelPage ? "db-ns dn" : ""
					}`}
					onClick={() => closeDropdown()}
				>
					<div className="flex items-center justify-between">
						<div
							className="ph3 pv2 flex items-center w-100"
							style={{ height: "84px" }}
						>
							<Link
								to="/"
								className={`f3 fw7 no-underline mr3 ${homeLogoClass}`}
							>
								<img
									alt="logo"
									src={logo}
									className="h2 db-ns dn"
									style={{ minWidth: "60px" }}
								/>
								<img
									alt="logo"
									src={logoSmall}
									className="h2 dn-ns db"
									style={{ minWidth: "29px" }}
								/>
							</Link>
							{searchPage ? (
								<div className="w-100 mw6">
									<LocationSearch
										large={true}
										searchOnClick={true}
									/>
								</div>
							) : null}
						</div>
						{!bookPage ? this.renderNav() : null}
					</div>
				</div>
				{notHome ? (
					<div className="db-ns dn" style={{ height: "84px" }} />
				) : null}
			</header>
		);
	}

	renderNav() {
		const { user, token, openModal, signOut, closeDropdown } = this.props;
		if (!token || !user || !user.firstName)
			return (
				<div className="flex-l dn items-center mr3 f6 fw5">
					<span id="my_custom_link" className="nowrap mr4 pointer">
						24/7 Support
					</span>
					<span
						className="nowrap mr4 pointer"
						onClick={() => openModal("signUp")}
					>
						Sign Up
					</span>
					<span
						className="nowrap pointer"
						onClick={() => openModal("signIn")}
					>
						Sign In
					</span>
				</div>
			);

		return (
			<div className="mr3 flex-l dn items-center f6 fw5">
				<span id="my_custom_link" className="nowrap mr4 pointer">
					24/7 Support
				</span>
				<Dropdown
					id="reservations"
					buttonComponent={
						<span className="nowrap mr4 pointer">Reservations</span>
					}
					dropdownClass="right-0-important mr2 w-100 mw5-5"
					dropdownComponent={
						<div className="bg-white w-100">
							<Reservations />
						</div>
					}
				/>
				<Dropdown
					id="account"
					buttonComponent={
						<div className="h2 w2 fw6 br-pill bg-gray white flex items-center justify-center ttu">
							{user.firstName[0]}
							{user.lastName[0]}
						</div>
					}
					dropdownClass="right-0-important mr2 w-100 mw5"
					dropdownComponent={
						<div className="bg-white w-100 mw5">
							<ul className="list pa0 ma0 fw6">
								<li
									className="ma0 ph3 pointer"
									onClick={() => closeDropdown()}
								>
									<Link
										to="/account"
										className="link gray hover-dark-gray"
									>
										<div className="pv3 bb b--light-gray">
											Account
										</div>
									</Link>
								</li>
								<li
									className="ma0 pa3 pointer gray hover-dark-gray link"
									onClick={() => {
										signOut();
										closeDropdown();
									}}
								>
									Sign out
								</li>
							</ul>
						</div>
					}
				/>
			</div>
		);
	}
}

export default withRouter(Nav);
