export const SET_PRICE_FILTER = "SET_PRICE_FILTER";
export const SET_STAR_FILTER = "SET_STAR_FILTER";
export const SET_REVIEW_FILTER = "SET_REVIEW_FILTER";
export const SET_BOUNDS_FILTER = "SET_BOUNDS_FILTER";
export const SET_NEIGHBORHOODS_FILTER = "SET_NEIGHBORHOODS_FILTER";
export const SET_PROPERTY_FILTER = "SET_PROPERTY_FILTER";
export const CLEAR_PROPERTY_FILTER = "CLEAR_PROPERTY_FILTER";

export function setPriceFilter(minPrice, maxPrice, dispatch) {
	dispatch({ type: SET_PRICE_FILTER, minPrice, maxPrice });
}

export function setStarFilter(rating, selected, dispatch) {
	dispatch({ type: SET_STAR_FILTER, rating, selected });
}

export function setReviewFilter(rating, selected, dispatch) {
	dispatch({ type: SET_REVIEW_FILTER, rating, selected });
}

export function setBoundsFilter(bounds, dispatch) {
	if (bounds && bounds.west === bounds.east) return;
	if (bounds && bounds.north === bounds.south) return;
	dispatch({ type: SET_BOUNDS_FILTER, bounds });
}

export function setNeighborhoodFilter(neighborhoods, dispatch) {
	dispatch({ type: SET_NEIGHBORHOODS_FILTER, neighborhoods });
}

export function setPropertyFilter(property, selected, dispatch) {
	dispatch({ type: SET_PROPERTY_FILTER, property, selected });
}

export function clearPropertyFilter(dispatch) {
	dispatch({ type: CLEAR_PROPERTY_FILTER });
}
