import React, { PureComponent } from "react";
import { format } from "date-fns";
import Dropdown from "../Dropdown";
import DatePicker from "../DatePicker";
import Input from "../Input";

export default class DateSearch extends PureComponent {
	render() {
		const { closeDropdown, hideBorder } = this.props;
		return (
			<div>
				<Dropdown
					id="datePicker"
					buttonComponent={
						<Input hideBorder={hideBorder}>
							{this.formatDates()}
						</Input>
					}
					dropdownComponent={
						<div className="w-100 h-auto-ns h-100 flex flex-column justify-start-ns justify-between">
							<DatePicker />
							<div className="pa3 bn-ns bt b--light-gray flex items-center justify-end">
								<button
									onClick={() => closeDropdown()}
									className="pa0-ns pa3 w-auto-ns w-100 br0-ns br2 bn bg-transparent-ns bg-blue blue-ns white f6 fw6 pointer self-end"
								>
									<span className="db-ns dn">Done</span>
									<span className="dn-ns db f5 fw6">
										Done
									</span>
								</button>
							</div>
						</div>
					}
					// preventFullscreen={true}
				/>
			</div>
		);
	}

	formatDates() {
		const { options } = this.props;
		const { checkInDate, checkOutDate } = options;
		if (!checkInDate && !checkOutDate) return "";
		if (checkInDate && !checkOutDate)
			return format(checkInDate, "ddd, MMM D");
		if (checkInDate && checkOutDate) {
			// Months and years are the same
			// if (getMonth(checkInDate) === getMonth(checkOutDate)) {
			// 	return `${format(checkInDate, "ddd, MMM D")} - ${format(
			// 		checkOutDate,
			// 		"D"
			// 	)}`;
			// }

			return `${format(checkInDate, "ddd, MMM D")} - ${format(
				checkOutDate,
				"ddd, MMM D"
			)}`;
		}
	}
}
