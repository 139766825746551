import React, { PureComponent } from "react";

export default class Component extends PureComponent {
	render() {
		const { children, hideShadow, hideBorder, onClick } = this.props;
		return (
			<div
				className={`w-100 ${hideShadow ? "" : "shadow"} ${
					hideBorder ? "" : "ba"
				} pa3 fw7 f5 br2 b--light-gray bg-white black`}
				onClick={onClick}
			>
				{children}
			</div>
		);
	}
}
